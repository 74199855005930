/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import {
	AppBar,
	Breakpoint,
	Drawer,
	Stack,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { FC, PropsWithChildren, useCallback, useState } from 'react';
import { sidebarAppBarContainerSX } from './styles/appBar/container';
import { sidebarAppBarContentSX } from './styles/appBar/content';
import { SidebarContent } from './parts/Content';
// import { sidebarAppBarContainerSX } from '@/components/blocks/SideBar/styles/appBar/container';
// import { SidebarContent } from '@/components/blocks/SideBar/parts/Content';
// import { sidebarAppBarContentSX } from '@/components/blocks/SideBar/styles/appBar/content';

type Props = {
	title: string;
	href?: string;
	mobileBreakpoint: Breakpoint;
	scrollable?: boolean;
};

export const Sidebar: FC<PropsWithChildren<Props>> = ({
	title,
	href,
	mobileBreakpoint = 'sm',
	scrollable = false,
	children,
}) => {
	const theme = useTheme();
	const [open, setOpen] = useState<boolean>(false);
	const isMobile = useMediaQuery(theme.breakpoints.down(mobileBreakpoint));
	const toggleOpen = useCallback(() => {
		if (isMobile) setOpen(!open);
	}, [open, isMobile]);

	return (
		<SidebarContent {...{ children, href, isMobile, scrollable, title, toggleOpen }} />
	);
};
