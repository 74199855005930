/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { Linkable } from '@/components/blocks/Linkable';
import { Accordion, AccordionSummary, Box, Divider, Paper, Typography } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { FC, PropsWithChildren } from 'react';
import { sidebarPaperSX } from '../styles/paper';
import { sidebarAccordionSummarySX } from '../styles/accordionSummary';
import { accountSidebarSectionSX } from '@/components/content/AccountSidebar/styles/section';

type Props = {
	title: string;
	href?: string;
	scrollable?: boolean;
	isMobile: boolean;
	toggleOpen: () => void;
};

export const SidebarContent: FC<PropsWithChildren<Props>> = ({
	title,
	href,
	isMobile,
	toggleOpen,
	scrollable = false,
	children,
}) => (
	<Box sx={accountSidebarSectionSX}>
		{href ? (
			<Linkable
				id={`account-sidebar-${title.toLowerCase()}`}
				data-testid={`account-sidebar-${title.toLowerCase()}`}
				href={href}
			>
				<Typography variant="body3Bold">{title}</Typography>
			</Linkable>
		) : (
			<>
				{isMobile ? <Divider sx={{ marginY: '10px' }} /> : null}
				<Typography variant="h8">{title}</Typography>
			</>
		)}
		{children}
	</Box>
);
