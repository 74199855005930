/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { SxProps, Theme } from '@mui/material';

export const accountSidebarSectionSX: SxProps<Theme> = {
	backgroundColor: '#fafafb',
	textIndent: '0',
	// overrides:{ typography: { fontSize: '84pt' } }
};
