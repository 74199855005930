import { Sidebar } from '@/components/blocks/CustomerServiceSideBar';
import { NewCustomerServiceSidebarContent } from '@/components/content/NewCustomerServiceSidebar/Content';

import { ID } from '@/data/types/Basic';
import { Breakpoint } from '@mui/material';
import { FC } from 'react';

const mobileBreakpoint: Breakpoint = 'md';

export const NewCustomerServiceSidebar: FC<{ id: ID }> = () => {
	const title = '';
	return (
		<Sidebar title={title} mobileBreakpoint={mobileBreakpoint} scrollable={true}>
			{<NewCustomerServiceSidebarContent />}
		</Sidebar>
	);
};

export default NewCustomerServiceSidebar;
