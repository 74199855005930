import { Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useContentRecommendation } from '@/data/Content/ContentRecommendation';
import { CUSTOMER_SERVICE_SIDENAV } from '@/data/constants/marketing';
import { renderContent } from '@/utils/renderContent';

export const NewCustomerServiceSidebarContent: FC = () => {
	const { data: espotData } = useContentRecommendation(CUSTOMER_SERVICE_SIDENAV);

	return (
		<>
			{espotData && Array.isArray(espotData) ? (
				<Stack component="div" sx={{'& p': {marginBottom: '1rem'}}}>
					<Typography variant="body2" sx={{ color: '#4A5055' }}>
						{espotData?.map((content) => renderContent(content))}
					</Typography>
				</Stack>
			) : null}
		</>
	);
};
